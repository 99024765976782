jQuery(document).ready(function ($) {
  // ============================================= POPUP PROMOTION HIKASHOP
  // $(".hika__popup")
  //   .find("i")
  //   .click(function () {
  //     $(".hika__popup").removeClass("active");
  //   });
  // $(document).on("mouseup", function (e) {
  //   if (!e.target.closest(".hika__popup")) {
  //     $(".hika__popup").removeClass("active");
  //   }
  // });
  // ============================================= EO POPUP PROMOTION HIKASHOP
});

// ============================ localstorage
function popupPromo() {
  var setBtn = document.querySelector("#popup__btn");
  var setPopup = document.querySelector(".hika__popup");
  let now = new Date();
  setBtn.addEventListener("click", function () {
    setPopup.classList.toggle("active");

    if (setPopup.classList.contains("active")) {
      value = "active";
    } else {
      value = "disabled";
    }

    // localstorage

    const item = {
      value: value,
      expiry: now.getTime() + 80000,
    };

    localStorage.setItem("Popup", JSON.stringify(item));
    console.log(item.expiry);
  });

  // localStorage.setItem("Popup", JSON.stringify("active"));
}

popupPromo();

var activePop;
let nowT = new Date();
console.log(nowT.getTime());
let getPopup = JSON.parse(localStorage.getItem("Popup"));

if (!getPopup) {
  return null;
}

if (getPopup.value === "disabled") {
  document.querySelector(".hika__popup").classList.remove("active");
}
if (nowT.getTime() > getPopup.expiry) {
  localStorage.removeItem("Popup");
  console.log(getPopup.expiry);
  return null;
}
// ============================ eo localstorage
